import dayjs from 'dayjs'
import * as pluralize from 'pluralize'
import React, { useMemo, useState } from 'react'
import { keyBy } from 'lodash'
import { useVeeziWebSessionsAll } from '../hooks/veezi-web-sessions'
import { range } from '../util/array'
import DateFilter from './date-filter'
import EventList from './event-list'
import EventTypeFilter from './event-type-filter'
import LocationFilter from './location-filter'
import EventsByDate from './events-by-date'
import styles from './homepage-events.module.css'
import ThemeBanner from './theme-banner'
import { groupBy } from '../util/array'

const HomepageEventsByDate = ({
  veeziWebSessions,
  dates,
  events,
  showEventTypes,
  screenToLocation,
  locationFilter,
}) => {
  if (
    !(events || []).filter(e => !!e.startDate).length &&
    !(veeziWebSessions || []).length
  ) {
    return <div className={styles.noEvents}>Check back soon for updates.</div>
  }
 
  return (
    <EventsByDate
      className={styles.eventsByDate}
      events={events}
      veeziWebSessions={veeziWebSessions}
      type="ads"
      screenToLocation={screenToLocation}
      locationFilter={locationFilter}
    />
  )
}

const HomepageEventsByType = ({
  veeziWebSessions,
  date,
  events,
  screenToLocation,
  locationFilter,
}) => {
  const films = []
  const otherEvents = []
  events.forEach(event => {
    const sessions = event.veeziFilmId
      ? veeziWebSessions.filter(
        s =>
          s.FilmId === event.veeziFilmId &&
          dayjs(s.PreShowStartTime).isSame(date, 'day')
      )
      : []

    if (
      !sessions.length &&
      (!event.startDate || !dayjs(event.startDate).isSame(date, 'day'))
    ) {
      return
    }

    if (event.veeziFilmId) {
      event.sessions = sessions
    }

    if (event.eventTypes.includes('film')) {
      films.push(event)
    }

    if (event.eventTypes.includes('event')) {
      otherEvents.push(event)
    }
  })

  if (!films.length && !otherEvents.length) {
    return <div className={styles.noEvents}>Check back soon for updates.</div>
  }
  return (
    <>
      {films.length ? (
        <div className={styles.films}>
          <div className="text-center">
            <h3 className={styles.eventTypeHeader}>Films</h3>
          </div>
          <EventList events={films} listStyle="grid" type="ads" screenToLocation={screenToLocation} locationFilter={locationFilter} />
        </div>
      ) : null}
      {otherEvents.length ? (
        <div className={styles.events}>
          <div className="text-center">
            <h3 className={styles.eventTypeHeader}>Events</h3>
          </div>
          <EventList events={otherEvents} listStyle="grid" screenToLocation={screenToLocation} locationFilter={locationFilter} />
        </div>
      ) : null}
    </>
  )
}

const HomepageEvents = ({ events, locations }) => {

  const currentDay = dayjs().startOf('day')
  const [selected, setSelected] = useState('all')
  const [dateRange, setDateRange] = useState(
    range(0, 6).map(d =>
      dayjs(currentDay)
      .add(d, 'day')
      .startOf('day')
      .toDate()
      )
      )
      const [eventTypeFilter, setEventTypeFilter] = useState('Everything')
      const [locationFilter, setLocationFilter] = useState('Everywhere')
  const veeziWebSessions = useVeeziWebSessionsAll({})

  const groupByLocations = groupBy(veeziWebSessions, s => {
    const location = locations.find(l => l.screens.includes(s.ScreenId))

    if (location) return location.title

    // All location will temporarily default to Golden Mile Tower for now
    return 'Golden Mile Tower'
  })
  let veeziData = veeziWebSessions
  if (locationFilter === 'Everywhere') {
    veeziData = veeziWebSessions || []
  } else {
    veeziData = groupByLocations[locationFilter] || []
  }
  const filteredEvents = useMemo(() => {
    const eventTypePredicate = e =>
      !eventTypeFilter ||
      eventTypeFilter === 'Everything' ||
      e.eventTypes.includes(eventTypeFilter)

    const eventDatePredicate = e =>
      (!e.startDate && e.veeziFilmId) ||
      dayjs(e.startDate).isBetween(dateRange[0], dateRange[6], 'day', '[]')

    return (events || []).filter(
      e => eventTypePredicate(e) && eventDatePredicate(e)
    )
  }, [events, dateRange, eventTypeFilter])
  const filteredEventsKeyBy = keyBy(filteredEvents, 'veeziFilmId')
  const filteredSessions = useMemo(
    () =>
      (veeziData || []).filter(
        s =>
          filteredEventsKeyBy[s.FilmId] &&
          (!s.PreShowStartTime ||
            dayjs(s.PreShowStartTime).isBefore(
              dayjs(dateRange[6]).endOf('day')
            ))
      ),
    [veeziData, dateRange, filteredEvents]
  )

  const validEvents = (events || []).filter(
    e =>
      !eventTypeFilter ||
      eventTypeFilter === 'Everything' ||
      e.eventTypes.includes(eventTypeFilter)
  )

  const validEventIds = validEvents.map(e => e.veeziFilmId).filter(f => !!f)
  const validSessions = veeziData.filter(s =>
    validEventIds.includes(s.FilmId)
  )

  const datesWithSessions = validSessions.reduce((dates, s) => {
    if (s.PreShowStartTime)
      dates.push(
        dayjs(s.PreShowStartTime)
          .startOf('day')
          .toString()
      )
    return dates
  }, [])

  const datesWithEvents = validEvents.reduce((dates, e) => {
    if (e.startDate)
      dates.push(
        dayjs(e.startDate)
          .startOf('day')
          .toString()
      )
    return dates
  }, [])

  const allowedDates = [
    ...new Set([...datesWithSessions, ...datesWithEvents]),
  ].map(d => dayjs(d).toDate())

  let filterTitle
  let filterVerbage
  if (eventTypeFilter === 'Everything') {
    filterVerbage = `that's happening`
  } else if (eventTypeFilter === 'film') {
    filterVerbage = 'playing'
  } else if (eventTypeFilter === 'event') {
    filterVerbage = 'happening'
  }

  if (selected === 'all') {
    filterTitle = dayjs(currentDay).isSame(dateRange[0], 'day')
      ? `${filterVerbage} this week`
      : `${filterVerbage} that week`
  } else if (dayjs().isSame(selected, 'day')) {
    filterTitle = `${filterVerbage} today`
  } else {
    filterTitle = `${filterVerbage} on ${dayjs(selected).format('D MMM')}`
  }

  const screenToLocation = {}
  for (const item of locations) {
    for (const screen of (item.screens || [])) {
      screenToLocation[screen] = item.title
    }
  }

  return (
    <div className={styles.homepageEvents}>
      <h1
        className={`${styles.heading} flex flex-col md:flex-row justify-center items-center`}
      >
        <div className={styles.headingFilter}>
          <EventTypeFilter
            selected={eventTypeFilter}
            onClick={setEventTypeFilter}
            ariaControls="homepageEventsResults"
          ></EventTypeFilter>
          {' '} at {' '}
          <LocationFilter
            selected={locationFilter}
            onClick={setLocationFilter}
            ariaControls="homepageEventsResults"
            locations={locations}
          ></LocationFilter>
        </div>
        {filterTitle}
      </h1>
      <DateFilter
        className={styles.dateFilter}
        labelVerbage={`${pluralize(eventTypeFilter)} ${filterVerbage}`}
        ariaControls="homepageEventsResults"
        dateButtonClassName={styles.dateButton}
        filterButtonClassName={styles.filterButton}
        showCalendarWeeks={false}
        onChange={(data) => {
          setTimeout(() => {
            typeof googletag !== 'undefined' && googletag.pubads().refresh();
          }, 1000)
          setSelected(data)
        }}
        onRangeChange={r => setDateRange(r)}
        value={selected}
        allowedDates={allowedDates}
        showAll={true}
      />
      <div className={styles.themeBanner}>
        <ThemeBanner date={selected} />
      </div>
      <div id="homepageEventsResults" role="region" aria-live="polite">
        {selected === 'all' ? (
          <HomepageEventsByDate
            events={filteredEvents}
            veeziWebSessions={filteredSessions}
            dates={dateRange}
            showEventTypes={eventTypeFilter === 'Everything'}
            screenToLocation={screenToLocation}
            locationFilter={locationFilter}
          />
        ) : (
          <HomepageEventsByType
            events={filteredEvents}
            veeziWebSessions={filteredSessions}
            date={selected}
            screenToLocation={screenToLocation}
            locationFilter={locationFilter}
          />
        )}
      </div>
    </div>
  )
}

export default HomepageEvents