import { OutboundLink } from 'gatsby-plugin-google-analytics'
import PropTypes from 'prop-types'
import React from 'react'

import facebookIcon from '../images/facebook.svg'
import instagramIcon from '../images/instagram.svg'
import telegramIcon from '../images/telegram.svg'
import youtubeIcon from '../images/youtube.svg'
import styles from './social-media.module.css'

const SocialMedia = ({ socialMedia }) => {
  return (
    <div className="w-full">
      <h3 className={styles.title}>{socialMedia.title}</h3>
      <div className={styles.blurb}>{socialMedia.blurb}</div>
      <div
        className={`${styles.socialMedia} flex flex-col justify-center md:flex-row md:items-center`}
      >
        <div
          className={`${styles.socialMediaIcons} flex justify-between w-full md:w-auto`}
        >
          {socialMedia.telegram && (
            <OutboundLink
              href={`https://t.me/${socialMedia.telegram}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={telegramIcon} alt="Telegram" />
            </OutboundLink>
          )}
          {socialMedia.facebook && (
            <OutboundLink
              href={`https://www.facebook.com/${socialMedia.facebook}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={facebookIcon} alt="Facebook" />
            </OutboundLink>
          )}
          {socialMedia.instagram && (
            <OutboundLink
              href={`https://www.instagram.com/${socialMedia.instagram}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={instagramIcon} alt="Instagram" />
            </OutboundLink>
          )}
          {socialMedia.youtube && (
            <OutboundLink
              href={`https://www.youtube.com/user/${socialMedia.youtube}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={youtubeIcon} alt="Youtube" />
            </OutboundLink>
          )}
        </div>
      </div>
    </div>
  )
}

SocialMedia.propTypes = {
  socialMedia: PropTypes.object,
}

export default SocialMedia
