import dayjs from 'dayjs'
import React from 'react'

import Link from '../components/link'
import useBanners from '../hooks/useBanners'
import styles from './theme-banner.module.css'

const ThemeBanner = ({ date }) => {
  const dates =
    !date || date !== 'all'
      ? [date]
      : [dayjs().startOf('week'), dayjs().endOf('week')]

  const banners = useBanners(dates)

  return banners.map((b, i) => (
    <div
      aria-label={`Read more about ${b.title}`}
      className={styles.themeBanner}
      key={i}
    >
      <span className={styles.bannerText}>{b.bannerText}</span>
      <Link className={styles.readMore} to={b.slug} key={i}>
        Read More
      </Link>
    </div>
  ))
}

export default ThemeBanner
