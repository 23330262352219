import { useStaticQuery, graphql } from 'gatsby'
import dayjs from 'dayjs'

const useBanners = dates => {
  const {
    allMarkdownRemark: { nodes: banners },
  } = useStaticQuery(
    graphql`
      query BannerQuery {
        allMarkdownRemark(
          filter: {
            fields: { collection: { eq: "themes" } }
            frontmatter: {
              startDate: { ne: null }
              endDate: { ne: null }
              bannerText: { nin: [null, ""] }
            }
          }
        ) {
          nodes {
            frontmatter {
              startDate
              endDate
              title
              bannerText
            }
            fields {
              slug
            }
          }
        }
      }
    `
  )

  const filterBanner = b => {
    if (dates.length <= 1) {
      return dayjs(dates[0]).isBetween(b.startDate, b.endDate)
    }

    if (dates.length >= 2) {
      return (
        dayjs(dates[0]).isSameOrBefore(b.endDate) &&
        dayjs(dates[1]).isSameOrAfter(b.startDate)
      )
    }

    return false
  }

  return banners
    .map(b => ({ ...b.frontmatter, ...b.fields }))
    .filter(filterBanner)
}

export default useBanners
