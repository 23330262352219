import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import * as pluralize from 'pluralize'

import Dropdown, { DropdownItem } from './dropdown'
import styles from './event-type-filter.module.css'

const EventTypeFilter = ({ selected, ariaControls, onClick }) => {
  const {
    allMarkdownRemark: { distinct: types },
  } = useStaticQuery(graphql`
    {
      allMarkdownRemark {
        distinct(field: frontmatter___eventTypes)
      }
    }
  `)

  const handleSelectFilter = eventType => {
    if (onClick) onClick(eventType)
  }

  return (
    <>
      <Dropdown
        wrapperClassName={styles.dropdown}
        selected={selected}
        onClick={handleSelectFilter}
        ariaControls={ariaControls}
      >
        <DropdownItem value={'Everything'} key={0}>
          Everything
        </DropdownItem>
        {(types || [])
          .sort()
          .reverse()
          .map((t, i) => (
            <DropdownItem value={t} key={i + 1}>
              {pluralize(t)}
            </DropdownItem>
          ))}
      </Dropdown>
    </>
  )
}

export default EventTypeFilter
