import PropTypes from 'prop-types'
import React from 'react'

import Link from '../components/link'
import styles from './features.module.css'
import Image from './image'

const Features = ({ features }) => (
  <div className={`${styles.featuresWrapper} un-container`}>
    <div className={`container ${styles.features}`}>
      {features.map((f, i) => (
        <div className={styles.feature} key={i}>
          <div className={styles.imageWrapper}>
            <div className={styles.imageFrame}>
              <div className={styles.image}>
                {f.image && f.image.childImageSharp ? (
                  <Image
                    fluid={f.image.childImageSharp.fluid}
                    alt={f.title}
                  ></Image>
                ) : null}
              </div>
            </div>
          </div>
          <div className={styles.content}>
            <h2 className={styles.title}>{f.title}</h2>
            <div
              className={styles.body}
              dangerouslySetInnerHTML={{ __html: f.bodyHtml }}
            ></div>
            <Link className={`btn btn-primary ${styles.btn}`} to={f.linkURL}>
              {f.callToAction}
            </Link>
          </div>
        </div>
      ))}
    </div>
  </div>
)

Features.propTypes = {
  features: PropTypes.arrayOf(PropTypes.object),
}

export default Features
