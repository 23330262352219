import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import * as pluralize from 'pluralize'
import slugify from 'slugify'
import Dropdown, { DropdownItem } from './dropdown'
import styles from './location-filter.module.css'

const EventTypeFilter = ({ selected, ariaControls, onClick, locations }) => {

  const handleSelectFilter = eventType => {
    if (onClick) onClick(eventType)
  }

  return (
    <>
      <Dropdown
        wrapperClassName={styles.dropdown}
        selected={selected}
        onClick={handleSelectFilter}
        ariaControls={ariaControls}
      >
        <DropdownItem value={'Everywhere'} key={0}>
          Everywhere
        </DropdownItem>
        {locations.map((location, index) =>
          <DropdownItem value={location.title} key={index + 1}>
            {location.title}
          </DropdownItem>
        )}
      </Dropdown>
    </>
  )
}

export default EventTypeFilter
