import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Swipeable } from 'react-touch'

import { range } from '../util/array'
import { classes } from '../util/components'
import ArrowButton from './arrow-button'
import CarouselSlide from './carousel-slide'
import styles from './carousel.module.css'

const CAROUSEL_READY = 'ready'
const CAROUSEL_HEADING_BACK = 'heading back'
const CAROUSEL_HEADING_FORWARD = 'heading forward'
const CAROUSEL_OTW_BACK = 'going back'
const CAROUSEL_OTW_FORWARD = 'going forward'

const CarouselWithSlides = ({
  className,
  wrapperClassName,
  slides,
  autoScroll = true,
  autoScrollInterval = 5000,
  isPaused = false,
  previewSlides = true,
  onChangeSlide,
  lock = false,
  pauseOnHover = false,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const oneBefore = currentSlide === 0 ? slides.length - 1 : currentSlide - 1
  const twoBefore =
    currentSlide > 1
      ? currentSlide - 2
      : oneBefore > 0
      ? oneBefore - 1
      : slides.length - 1

  const oneAfter = currentSlide === slides.length - 1 ? 0 : currentSlide + 1
  const twoAfter =
    currentSlide < slides.length - 2
      ? currentSlide + 2
      : oneAfter === slides.length - 1
      ? 0
      : oneAfter + 1

  const [carouselState, setCarouselState] = useState(CAROUSEL_READY)
  const [isInternalPaused, setIsInternalPaused] = useState(isPaused)
  const autoScrollTimeoutId = useRef(null)
  const internalClearTimeout = useCallback(() => {
    if (autoScrollTimeoutId.current) {
      clearTimeout(autoScrollTimeoutId.current)
      autoScrollTimeoutId.current = null
    }
  }, [])

  const goPrevious = useCallback(() => {
    if (lock) {
      return
    }

    if (carouselState === CAROUSEL_READY) {
      setCarouselState(CAROUSEL_HEADING_BACK)

      if (onChangeSlide) {
        onChangeSlide()
      }
    }
  }, [carouselState, onChangeSlide, lock])

  const goNext = useCallback(() => {
    if (lock) {
      return
    }

    if (carouselState === CAROUSEL_READY) {
      setCarouselState(CAROUSEL_HEADING_FORWARD)

      if (onChangeSlide) {
        onChangeSlide()
      }
    }
  }, [carouselState, onChangeSlide, lock])

  const manualGoPrevious = useCallback(() => {
    setIsInternalPaused(false)
    goPrevious()
  }, [goPrevious])

  const manualGoNext = useCallback(() => {
    setIsInternalPaused(false)
    goNext()
  }, [goNext])

  const autoGoNext = useCallback(() => {
    if (autoScrollTimeoutId.current) {
      internalClearTimeout()
    }
    if (!autoScroll) return

    goNext()

    if (autoScroll) {
      autoScrollTimeoutId.current = setTimeout(
        () => autoGoNext(),
        autoScrollInterval
      )
    }
  }, [autoScroll, autoScrollInterval, goNext, internalClearTimeout])

  useEffect(() => {
    if (carouselState === CAROUSEL_HEADING_BACK) {
      setCarouselState(CAROUSEL_OTW_BACK)
    } else if (carouselState === CAROUSEL_HEADING_FORWARD) {
      setCarouselState(CAROUSEL_OTW_FORWARD)
    }
  }, [autoScrollInterval, carouselState])

  useEffect(() => {
    if (autoScrollTimeoutId.current === null && autoScroll) {
      autoScrollTimeoutId.current = setTimeout(
        () => autoGoNext(),
        autoScrollInterval
      )
    }

    return () => {
      if (autoScrollTimeoutId.current) {
        internalClearTimeout()
      }
    }
  }, [autoGoNext, autoScroll, autoScrollInterval, internalClearTimeout])

  const onAfterTransition = useCallback(() => {
    setCarouselState(CAROUSEL_READY)

    if (isInternalPaused) return

    if (carouselState === CAROUSEL_OTW_BACK) {
      setCurrentSlide(oneBefore)
    } else if (carouselState === CAROUSEL_OTW_FORWARD) {
      setCurrentSlide(oneAfter)
    }
  }, [isInternalPaused, carouselState, oneBefore, oneAfter])

  const onIndicatorClick = useCallback(
    i => {
      internalClearTimeout()
      setCurrentSlide(i)

      autoScrollTimeoutId.current = setTimeout(
        () => autoGoNext(),
        autoScrollInterval
      )
    },
    [autoGoNext, autoScrollInterval, internalClearTimeout]
  )

  const getSlideStyle = useCallback(
    slideIndex => {
      const style = {
        display: 'block',
      }

      const shift =
        carouselState === CAROUSEL_OTW_BACK
          ? 100
          : carouselState === CAROUSEL_OTW_FORWARD
          ? -100
          : 0

      // Fixes for 2 slides carousel
      let updatedOneAfter = slides.length === 2 ? 2 : oneAfter

      switch (slideIndex) {
        case oneBefore:
          style.left = `calc(${-100 + shift}%)`
          style.display =
            carouselState === CAROUSEL_OTW_FORWARD ? 'none' : 'block'
          break

        case currentSlide:
          style.left = `${shift}%`
          break

        case updatedOneAfter:
          style.left = `calc(${100 + shift}%)`
          style.display = carouselState === CAROUSEL_OTW_BACK ? 'none' : 'block'
          break

        default:
          style.left = `calc(${200 + shift}%)`
          break
      }

      style.transition = [
        CAROUSEL_HEADING_BACK,
        CAROUSEL_HEADING_FORWARD,
        CAROUSEL_READY,
      ].includes(carouselState)
        ? 'none'
        : 'left 0.3s'

      return style
    },
    [carouselState, currentSlide, oneAfter, oneBefore, slides.length]
  )

  const onMouseEnter = useCallback(() => {
    if (
      pauseOnHover &&
      autoScrollTimeoutId.current &&
      carouselState === CAROUSEL_READY
    ) {
      setIsInternalPaused(true)
      internalClearTimeout()
    }
  }, [carouselState, internalClearTimeout, pauseOnHover])

  const onMouseLeave = useCallback(() => {
    if (
      pauseOnHover &&
      !autoScrollTimeoutId.current &&
      carouselState === CAROUSEL_READY
    ) {
      setIsInternalPaused(false)
      autoScrollTimeoutId.current = setTimeout(
        () => autoGoNext(),
        autoScrollInterval
      )
    }
  }, [autoGoNext, autoScrollInterval, carouselState, pauseOnHover])

  const twoBeforeSlideClone = (
    <CarouselSlide
      style={{
        left: carouselState === CAROUSEL_OTW_BACK ? '-100%' : '-200%',
        transition: carouselState === CAROUSEL_READY ? 'none' : 'left 0.3s',
      }}
      onAfterTransition={onAfterTransition}
    >
      {slides[twoBefore]}
    </CarouselSlide>
  )

  const twoAfterSlideClone = (
    <CarouselSlide
      style={{
        left: carouselState === CAROUSEL_OTW_FORWARD ? '100%' : '200%',
        transition: carouselState === CAROUSEL_READY ? 'none' : 'left 0.3s',
      }}
      onAfterTransition={onAfterTransition}
    >
      {slides[twoAfter]}
    </CarouselSlide>
  )

  const CarouselIndicators = () => {
    const indicatorStyle = {
      animationDuration: autoScrollInterval / 1000 + 's',
      animationPlayState:
        !isInternalPaused && carouselState === CAROUSEL_READY
          ? 'running'
          : 'paused',
    }
    return (
      <div
        className="flex flex-row justify-center"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {range(0, slides.length - 1).map(i => (
          <div
            key={i}
            className={`${styles.indicator} ${
              i === currentSlide && carouselState === CAROUSEL_READY
                ? styles.indicatorSelected
                : ''
            } cursor-pointer`}
            onClick={() => onIndicatorClick(i)}
          >
            <div className={styles.indicatorLeftCircle}>
              <div style={indicatorStyle} />
            </div>
            <div className={styles.indicatorRightCircle}>
              <div style={indicatorStyle} />
            </div>
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className={classes(styles.carouselWrapper, wrapperClassName)}>
      <div
        className={classes(styles.carousel, className)}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <Swipeable
          onSwipeLeft={() => manualGoNext()}
          onSwipeRight={() => manualGoPrevious()}
        >
          <div>
            {twoBeforeSlideClone}
            {twoAfterSlideClone}

            {slides.map((s, i) => (
              <CarouselSlide
                key={i}
                style={getSlideStyle(i)}
                isCurrent={currentSlide === i}
              >
                {s}
              </CarouselSlide>
            ))}
            {slides.length === 2 ? (
              <CarouselSlide
                style={getSlideStyle(2)}
                isCurrent={currentSlide === oneAfter}
              >
                {slides[oneAfter]}
              </CarouselSlide>
            ) : (
              ''
            )}
          </div>
        </Swipeable>

        {slides.length > 1 && !lock ? (
          <>
            <ArrowButton
              direction="left"
              onClick={() => manualGoPrevious()}
              className={styles.previous}
            />
            <ArrowButton
              direction="right"
              onClick={() => manualGoNext()}
              className={styles.next}
            />
          </>
        ) : null}
        <div
          className={styles.blackoutLeft}
          style={!previewSlides ? { background: 'black' } : null}
          tabIndex={-1}
          onClick={goPrevious}
        ></div>
        <div
          className={styles.blackoutRight}
          style={!previewSlides ? { background: 'black' } : null}
          tabIndex={-1}
          onClick={goNext}
        ></div>
      </div>
      {lock ? null : (
        <div className={styles.indicatorWrapper}>
          <CarouselIndicators />
        </div>
      )}
    </div>
  )
}

const Carousel = props => {
  const { slides, className, onMouseEnter, onMouseLeave } = props

  if (slides.length === 1) {
    return (
      <div
        className={classes(styles.carouselSingle, className)}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {slides[0]}
      </div>
    )
  } else if (slides.length > 1) {
    return <CarouselWithSlides {...props} />
  } else {
    return null
  }
}

Carousel.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.node),
  wrapperClassName: PropTypes.string,
  className: PropTypes.string,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  lock: PropTypes.bool,
  margin: PropTypes.number,
}

export default Carousel
