import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import Helmet from "react-helmet"
import Carousel from '../components/carousel'
import { SlideContext } from '../components/carousel-slide'
import Features from '../components/features'
import HomepageEvents from '../components/homepage-events'
import Image from '../components/image'
import Link from '../components/link'
import Promotions from '../components/promotions'
import SocialMedia from '../components/social-media'
import { useIsMobile } from '../hooks/screen-size'
import { arrayToHash } from '../util/object'
import styles from './index.module.css'
import { flatten } from '../util/array'
import SEO from '../components/seo';

const getCallToActionLink = (
  slide,
  carouselEventsById,
  carouselThemesById,
  carouselNewsById
) => {
  switch (slide.featureType) {
    case 'event':
      return carouselEventsById[slide.event]
        ? carouselEventsById[slide.event].slug
        : '/'

    case 'theme':
      return carouselThemesById[slide.theme]
        ? carouselThemesById[slide.theme].slug
        : '/'

    case 'news':
      return carouselNewsById[slide.news]
        ? carouselNewsById[slide.news].slug
        : '/'

    case 'url':
      return slide.callToActionUrl || '/'

    default:
      return '/'
  }
}

const carouselSlides = (
  isMobile,
  carouselEventsById,
  carouselThemesById,
  carouselNewsById,
  featuredItems
) => {
  return (featuredItems || []).map(e => {
    
    const imageSrc =
      e.mobileCoverImage && e.mobileCoverImage.childImageSharp
        ? [
            e.mobileCoverImage.childImageSharp.fluid,
            {
              ...e.desktopCoverImage.childImageSharp.fluid,
              media: '(min-width: 768px)',
            },
          ]
        : null

    if (isMobile) {
      return (
        <SlideContext.Consumer>
          {context => (
            <article
              className={`${styles.featuredItem} ${
                e.textColor === 'dark' ? styles.darkText : styles.lightText
              }`}
            >
              <h2 className={styles.featuredItemTitle}>{e.title}</h2>
              {imageSrc ? (
                <Link
                  to={getCallToActionLink(
                    e,
                    carouselEventsById,
                    carouselThemesById,
                    carouselNewsById
                  )}
                  aria-label={`Find out more about ${e.title}`}
                  tabIndex={context.isCurrent ? 0 : -1}
                >
                  <Image fluid={imageSrc} className={styles.coverImage} />
                </Link>
              ) : null}
              <div className={styles.featuredItemText}>
                <p className={styles.featuredItemBlurb}>{e.blurb}</p>
              </div>
              <div className={styles.featuredItemBtn}>
                <Link
                  to={getCallToActionLink(
                    e,
                    carouselEventsById,
                    carouselThemesById,
                    carouselNewsById
                  )}
                  className="btn-primary inline-block text-center"
                  aria-label={`Find out more about ${e.title}`}
                  tabIndex={context.isCurrent ? 0 : -1}
                >
                  {e.callToAction || 'Find Out More'}
                </Link>
              </div>
            </article>
          )}
        </SlideContext.Consumer>
      )
    }

    return (
      <SlideContext.Consumer>
        {context => (
          <article
            className={`${styles.featuredItem} ${
              e.textColor === 'dark' ? styles.darkText : styles.lightText
            }`}
          >
            {imageSrc ? (
              <Link
                to={getCallToActionLink(
                  e,
                  carouselEventsById,
                  carouselThemesById,
                  carouselNewsById
                )}
                aria-label={`Find out more about ${e.title}`}
                tabIndex={context.isCurrent ? 0 : -1}
              >
                <Image fluid={imageSrc} className={styles.coverImage} />
              </Link>
            ) : null}
            <span className={styles.slideOverlay} />
            <div className={styles.featuredItemText}>
              <h2 className={styles.featuredItemTitle}>{e.title}</h2>
              <p className={styles.featuredItemBlurb}>{e.blurb}</p>
              <div>
                <Link
                  to={getCallToActionLink(
                    e,
                    carouselEventsById,
                    carouselThemesById,
                    carouselNewsById
                  )}
                  className="btn btn-primary text-center"
                  aria-label={`Find out more about ${e.title}`}
                  tabIndex={context.isCurrent ? 0 : -1}
                >
                  {e.callToAction || 'Find Out More'}
                </Link>
              </div>
            </div>
          </article>
        )}
      </SlideContext.Consumer>
    )
  })
}

export const HomePageTemplate = ({
  featuredItems,
  promotions,
  events,
  locations,
  features,
  socialMedia,
  carouselEvents,
  carouselThemes,
  carouselNews,
}) => {
  const isMobile = useIsMobile()
  const carouselEventsById = useMemo(
    () => arrayToHash(carouselEvents, e => e.id),
    [carouselEvents]
  )
  const carouselThemesById = useMemo(
    () => arrayToHash(carouselThemes, t => t.id),
    [carouselThemes]
  )
  const carouselNewsById = useMemo(() => arrayToHash(carouselNews, t => t.id), [
    carouselNews,
  ])

  const slides = carouselSlides(
    isMobile,
    carouselEventsById,
    carouselThemesById,
    carouselNewsById,
    featuredItems
  )

  return (
    <>
    <SEO 
      title={"Cinema Singapore | Movie Theater | Indie & Foreign Movies"}
      description={"The Projector revives a historic movie theatre as a creative platform for the best of films and events. The best cinema in Singapore for Indie & foreign movies."}
    />
    <Helmet>
    <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "MovieTheater",
            "name": "The Projector",
            "logo": "https://theprojector.sg/static/81adf0cfb580ba7f0a199627d2799d47/4c270/tp-logo-allwhite.png",
              "image": [
          "https://theprojector.sg/static/afd4561dfe9721eb440d9d3ae24f4a22/c5de7/about-2.png",
          "https://theprojector.sg/static/96813784de65a1256c7f95c402a11659/f11ce/green-room.png",
          "https://theprojector.sg/static/9c64b829805cac2e8376bb05689c3515/0f953/about2.png"
                     ],
            "url": "https://theprojector.sg/",
            "email": "info@theprojector.sg",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "6001 Beach Rd, #05-00 GOLDEN MILE TOWER",
              "addressLocality": "Singapore",
              "postalCode": "199589",
              "addressCountry": "SG"
            },
             "currenciesAccepted" : "SGD",
             "paymentAccepted":["Cash","Credit Card"],
            "geo": {
              "@type": "GeoCoordinates",
              "latitude": 1.3019677,
              "longitude": 103.8641024
            },
                 "areaServed": [{
                  "@type": "City",
                  "name": "Singapore"            
                  }],
            "openingHoursSpecification": [{
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday"
              ],
              "opens": "16:00",
              "closes": "20:30"
            },{
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": [
                "Saturday",
                "Sunday",
                "PublicHolidays"
              ],
              "opens": "13:00",
              "closes": "20:30"
            }],
            "sameAs": [
              "https://www.facebook.com/TheProjectorSG/",
              "https://twitter.com/theprojectorsg",
              "https://www.instagram.com/theprojectorsg/",
              "https://www.youtube.com/user/theprojectorsg/",
              "https://www.linkedin.com/company/theprojectorsg/",
              "https://goo.gl/maps/R2UnkbJ6xbyA8TUi8",
              "https://www.tiktok.com/@theprojectorsg",
              "https://www.tripadvisor.in/Attraction_Review-g294265-d7964080-Reviews-The_Projector-Singapore.html",
              "https://en.wikipedia.org/wiki/The_Projector",
              "https://www.firststopsingapore.com/en/place/the-projector/",
              "https://foursquare.com/v/the-projector/4da0803dbb206ea88004ddfd"
            ],
            "department": [{
              "@type": "MovieTheater",
              "name": "Projector X: Riverside",
              "image": ["https://theprojector.sg/static/0b506a8b5fd19d44cba2cb80be4b997f/0f953/neon-cinema-seats.png",
                       "https://theprojector.sg/static/755265bcfd86953016b3991ee00a72b6/2ce30/neon-booth.png",
                        "https://theprojector.sg/static/6c07bdd90af10285852597ac81f3f77f/f0d45/rsp-bar.png"
          ],
              "openingHoursSpecification": [{
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": [
                  "Wednesday",
                  "Thursday",
                  "Friday"
                ],
                "opens": "16:00",
                "closes": "20:30"
              },{
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": [
                  "Saturday",
                  "Sunday",
                  "Public Holidays"
                ],
                "opens": "13:00",
                "closes": "20:30"
              }],
          "address": {
                  "@type": "PostalAddress",
                  "streetAddress": "30 Merchant Road, Riverside Point #04-13",
                  "addressLocality": "Singapore",
                  "addressRegion": "SG",
                  "postalCode": "058282",
                  "addressCountry": "SG"
                  },
              "location": {
                  "@type": "Place",
                  "geo": {
                      "@type": "GeoCoordinates",
                      "latitude": "1.2903428848875114",
                      "longitude": "103.84407926137419"
                      }
                  }
            },
          
          {
              "@type": "MovieTheater",
              "name": "Projector X: Picturehouse",
              "image": ["https://theprojector.sg/static/8bc7cec9643ad4a32503299c4d167ecd/6b048/foyer-final.jpg",
                         "https://theprojector.sg/static/64ceea01aa234f0d76ed359f91a2435c/5175a/majestic-final.jpg",
                       "https://theprojector.sg/static/ef77f3da1d32db62c057e3303f397914/d2739/coffee-final.jpg"
          ],
              "openingHoursSpecification": [{
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": [
                  "Thursday",
                  "Friday"
                ],
                "opens": "16:00",
                "closes": "20:30"
              },{
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": [
                  "Sunday",
                  "Saturday"
                ],
                "opens": "13:00",
                "closes": "20:30"
              }],
          "address": {
                  "@type": "PostalAddress",
                  "streetAddress": "2 Handy Rd, #05-01",
                  "addressLocality": "Singapore",
                  "addressRegion": "SG",
                  "postalCode": "229233",
                  "addressCountry": "SG"
                  },
          "location": {
                  "@type": "Place",
                  "geo": {
                      "@type": "GeoCoordinates",
                      "latitude": "1.2996572519093457",
                      "longitude": "103.84760959814878"
                      }
                  }
          
            }
          ]
          
          } 
                    
        `}
      </script> 
      </Helmet>
    <div className="container flex flex-col items-center">
      <Carousel className={styles.carousel} slides={slides} />
      <HomepageEvents events={events} locations={locations}></HomepageEvents>
      <Features features={features}></Features>
      <Promotions promotions={promotions} />
      <div className="mt-16">
        <SocialMedia socialMedia={socialMedia} />
      </div>
    </div>
    </>
  )
}

HomePageTemplate.propTypes = {
  title: PropTypes.string,
}

const HomePage = ({ data }) => {
  const { frontmatter } = data.page

  const events = data.events.edges.map(e => ({
    ...e.node.frontmatter,
    ...e.node.fields,
    themes: (e.node.fields.themes || []).map(t => ({
      ...t.fields,
      ...t.frontmatter,
    })),
    categories: (e.node.fields.categories || []).map(c => ({
      ...c.fields,
      ...c.frontmatter,
    })),
  }))
  const carouselEvents = data.carouselEvents.edges.map(e => ({
    ...e.node.frontmatter,
    ...e.node.fields,
  }))
  const carouselThemes = data.carouselThemes.edges.map(t => ({
    ...t.node.frontmatter,
    ...t.node.fields,
  }))
  const carouselNews = data.carouselNews.edges.map(t => ({
    ...t.node.frontmatter,
    ...t.node.fields,
  }))
  const locations = data.locations.edges.map(e => ({
    ...e.node.frontmatter,
    screens: Array.from(
      new Set(
        flatten(
          (e.node.fields.venues || []).map(v =>
            (v.frontmatter.screens || []).map(s => s.veeziScreenId)
          )
        )
      )
    ),
    venues: (e.node.fields.venues || []).map(e => ({ ...e.frontmatter })),
  }))
  return (
    <HomePageTemplate
      events={events}
      locations={locations}
      {...frontmatter}
      carouselEvents={carouselEvents}
      carouselThemes={carouselThemes}
      carouselNews={carouselNews}
    />
  )
}

const ContentShape = PropTypes.shape({
  allMarkdownRemark: PropTypes.shape({
    edges: PropTypes.arrayOf(
      PropTypes.shape({
        node: PropTypes.shape({
          frontmatter: PropTypes.object,
        }),
      })
    ),
  }),
})

HomePage.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.object,
    films: ContentShape,
    carouselEvents: ContentShape,
    carouselThemes: ContentShape,
  }),
}

export default HomePage

export const pageQuery = graphql`
  query HomePage(
    $featuredEventIds: [String]
    $featuredThemeIds: [String]
    $featuredNewsIds: [String]
  ) {
    page: markdownRemark(fields: { slug: { eq: "/home/" } }) {
      frontmatter {
        title
        featuredItems {
          title
          mobileCoverImage: coverImage {
            childImageSharp {
              fluid(maxWidth: 357, maxHeight: 226, quality: 92) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          desktopCoverImage: coverImage {
            childImageSharp {
              fluid(maxWidth: 1180, maxHeight: 555, quality: 92) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          blurb
          featureType
          event
          theme
          callToAction
          callToActionUrl
        }
        features {
          title
          image {
            childImageSharp {
              fluid(maxWidth: 678, maxHeight: 453, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          bodyHtml
          linkURL
          callToAction
        }
        promotions {
          title
          coverImage {
            childImageSharp {
              fluid(maxWidth: 678, maxHeight: 453, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          blurb
          callToAction
          linkURL
        }
        socialMedia {
          title
          blurb
          youtube
          facebook
          instagram
          telegram
        }
      }
    }
    events: allMarkdownRemark(
      filter: { fields: { collection: { eq: "films-and-events" } } }
    ) {
      edges {
        node {
          fields {
            ...EventFieldWithoutVenue
          }
          frontmatter {
            ...EventFrontmatter
          }
        }
      }
    }
    locations: allMarkdownRemark(
      filter: { fields: { collection: { eq: "locations" } } }
      sort: { fields: [frontmatter___title], order: [ASC] }
    ) {
      edges {
        node {
          frontmatter {
            title
            veeziToken
          }
          fields {
            venues {
              frontmatter {
                title
                screens {
                  veeziScreenId
                }
                accessibility
              }
            }
          }
        }
      }
    }
    carouselEvents: allMarkdownRemark(
      filter: {
        fields: {
          collection: { eq: "films-and-events" }
          id: { in: $featuredEventIds }
        }
      }
    ) {
      edges {
        node {
          fields {
            id
            slug
          }
        }
      }
    }
    carouselThemes: allMarkdownRemark(
      filter: {
        fields: { collection: { eq: "themes" }, id: { in: $featuredThemeIds } }
      }
    ) {
      edges {
        node {
          fields {
            id
            slug
          }
        }
      }
    }
    carouselNews: allMarkdownRemark(
      filter: {
        fields: { collection: { eq: "news" }, id: { in: $featuredNewsIds } }
      }
    ) {
      edges {
        node {
          fields {
            id
            slug
          }
        }
      }
    }
  }
`
