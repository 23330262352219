import React from 'react'

import styles from './carousel-slide.module.css'

export const SlideContext = React.createContext()

const CarouselSlide = ({ children, isCurrent, style, onAfterTransition }) => {
  return (
    <SlideContext.Provider value={{ isCurrent }}>
      <div
        onTransitionEnd={onAfterTransition}
        style={style}
        className={`${styles.carouselSlide} ${isCurrent ? styles.current : ''}`}
        tabIndex={isCurrent ? 0 : -1}
      >
        {children}
      </div>
    </SlideContext.Provider>
  )
}

export default CarouselSlide
